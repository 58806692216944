




















































import { Component, Vue } from "vue-property-decorator";
import SavedDocument from "@/app/domains/document/models/saved-document.model";
import DocumentService from "@/app/shared/services/document.service";

@Component
export default class Articles extends Vue {
  documents: Array<SavedDocument> = [];
  filterValue = "";

  private get filteredDocuments() {
    if (this.filterValue) {
      const filterValueLower = this.filterValue.toLowerCase();
      return this.documents.filter(
        (d) =>
          d.name.toLowerCase().includes(filterValueLower) ||
          d.projects.some((p) =>
            p.name.toLowerCase().includes(filterValueLower)
          )
      );
    }
    return this.documents;
  }

  async deleteDocumentById(documentId: number): Promise<void> {
    try {
      await this.$confirm(
        "Are you sure you want to delete the document?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
    } catch (error) {
      return;
    }

    try {
      await DocumentService.deleteDocumentById(documentId);
      this.documents = this.documents.filter((d) => d.id !== documentId);
      this.$message({
        message: "Document has been successfully deleted",
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
  }

  async removeLinkBetweenDocumentAndProject(
    documentId: number,
    projectId: number
  ): Promise<void> {
    try {
      await this.$confirm(
        "Are you sure you want to remove the link between the document and the project?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
    } catch (error) {
      return;
    }
    try {
      await DocumentService.removeLinkBetweenDocumentAndProject(
        documentId,
        projectId
      );
      const document = this.documents.find((d) => d.id === documentId);
      if (document) {
        document.projects = document.projects.filter((p) => p.id !== projectId);
      }
      this.$message({
        message:
          "The link between the document and the project has been successfully removed",
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
  }

  async created(): Promise<void> {
    try {
      this.documents = (await DocumentService.getDocuments()).data;
    } catch (error) {
      console.error(error);
    }
  }
}
